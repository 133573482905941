<template>
  <page-with-search-subscriber :scan-callback="onScan" v-slot="{ searchSubscriber }">
    <navigation title="Register outbound load carriers" />
    <f7-card>
      <f7-card-header>Scan item to register the outbound load carrier</f7-card-header>
      <scan-barcode-animation title="Scan load carrier" />
    </f7-card>

    <items-card-with-loading-state
      title="Outbound load carriers"
      :is-loading="isLoading"
      :items="registerableLoadCarriers"
    >
      <f7-list>
        <f7-list-item
          v-for="(registerableLoadCarrier, index) in registerableLoadCarriers"
          :key="index"
        >
          <template #inner>
            <div class="load_carrier">
              <div class="name-and-metrics">
                <div class="name">#{{ registerableLoadCarrier.loadCarrier.id }}</div>
                <div class="dimensions-and-weight" v-if="registerableLoadCarrier.registered">
                  <div class="dimensions">
                    {{ registerableLoadCarrier.loadCarrier.outerLength / 10 }} cm x
                    {{ registerableLoadCarrier.loadCarrier.outerWidth / 10 }} cm x
                    {{ registerableLoadCarrier.loadCarrier.outerHeight / 10 }} cm |
                    {{ registerableLoadCarrier.loadCarrier.grossWeight / 1000 }} kg
                  </div>
                </div>
              </div>
              <div class="registered">
                <check-mark-indicator :value="registerableLoadCarrier.registered" />
              </div>
            </div>
          </template>
        </f7-list-item>
      </f7-list>
    </items-card-with-loading-state>

    <register-load-carrier-popup
      v-if="selectedLoadCarrier"
      v-model="showRegisterPopup"
      @on-close="onCloseRegisterPopup"
      @registered-load-carrier="onRegisteredLoadCarrier(searchSubscriber)"
      :selected-load-carrier="selectedLoadCarrier"
    />

    <f7-fab
      position="center-bottom"
      v-if="!isLoading && unregisteredLoadCarriers === 0"
      @click="onComplete"
    >
      <font-awesome-icon :icon="['far', 'check']" />
    </f7-fab>
  </page-with-search-subscriber>
</template>
<script lang="ts" setup>
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'
import Navigation from '@components/AppNavigation.vue'
import useProjectPack from '@composables/useProjectPack'
import { computed, onMounted, ref } from 'vue'
import usePack from '@composables/usePack'
import { IRegisterableLoadCarrier } from '@graphql/pack/types'
import useLoading from '@composables/useLoading'
import ItemsCardWithLoadingState from '@components/ItemsCardWithLoadingState.vue'
import CheckMarkIndicator from '@pages/pack/single-shipment/component/CheckMarkIndicator.vue'
import ScanBarcodeAnimation from '@components/ScanBarcodeAnimation.vue'
import { IEvent, Subscriber } from '@/utilities/scanInput'
import { perceptibleToast } from '@services/perceptibleToast'
import { ILoadCarrier } from '@graphql/outbound/types'
import RegisterLoadCarrierPopup from '@pages/pack/single-shipment/component/RegisterLoadCarrierPopup.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { captureException } from '@sentry/vue'
import { f7 } from 'framework7-vue'
import useLoadCarrier from '@composables/useLoadCarrier'

const registerableLoadCarriers = ref<IRegisterableLoadCarrier[]>([])
const showRegisterPopup = ref<boolean>(false)
const selectedLoadCarrier = ref<ILoadCarrier | null>(null)

const unregisteredLoadCarriers = computed<number>(() => {
  return registerableLoadCarriers.value.filter(
    (loadCarrier: IRegisterableLoadCarrier) => !loadCarrier.registered
  ).length
})

const { activeProject, removeProject } = useProjectPack()
const { getRegisterableLoadCarriers, completePack } = usePack()
const { withAsyncLoading, isLoading } = useLoading()
const { isLoadCarrierBarcode } = useLoadCarrier()

onMounted(async () => {
  await refreshList()
})

const refreshList = async () => {
  await withAsyncLoading(async () => {
    registerableLoadCarriers.value = await getRegisterableLoadCarriers({
      packProjectId: activeProject.value!.packProject.id
    })
  })
}

const onScan = async (event: IEvent) => {
  const query = String(event.payload)

  if (!isLoadCarrierBarcode(query)) {
    await perceptibleToast.error('Scan a valid load carrier.')
    return
  }

  const loadCarrier = registerableLoadCarriers.value.find(
    (registerableLoadCarrier: IRegisterableLoadCarrier) => {
      return registerableLoadCarrier.loadCarrier.id === query
    }
  )

  if (!loadCarrier) {
    await perceptibleToast.error('Scan a valid load carrier.')
    return
  }

  onLoadCarrierScan(loadCarrier, event.subscriber)
}

const onLoadCarrierScan = (loadCarrier: ILoadCarrier, subscriber: Subscriber) => {
  subscriber.pause()
  showRegisterPopup.value = true
  selectedLoadCarrier.value = loadCarrier
}

const onCloseRegisterPopup = (subscriber: Subscriber) => {
  selectedLoadCarrier.value = null
  showRegisterPopup.value = false
  subscriber.resume()
}

const onRegisteredLoadCarrier = async (subscriber: Subscriber) => {
  await refreshList()

  onCloseRegisterPopup(subscriber)
}

const onComplete = async () => {
  try {
    await completePack({
      packProjectId: activeProject.value!.packProject.id
    })

    await removeProject(activeProject.value!.packProject.id)

    await perceptibleToast.finishedTask('Pack completed')

    await f7.views.main.router.navigate('/pack-entry/', {
      clearPreviousHistory: true
    })
  } catch (e) {
    captureException(e)

    await perceptibleToast.error(e.message)
  }
}
</script>

<style scoped lang="less">
.load_carrier {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dimensions-and-weight {
    font-size: 14px;
  }
}
</style>
