import { IArticle, IArticleBatch } from '@graphql/article/types'
import { ILocation, IWarehouse } from '@graphql/location/types'
import { IPurchaseOrderLine, ISite } from '@graphql/purchaseOrder/types'
import { IConnection, ID, IGraphQLResponse, INode } from '@graphql/types'
import { ICompany, ISearchResult } from '@graphql/search/types'
import {
  IInboundColloConnection,
  IInboundSortLoadCarrier,
  IInboundSortLoadCarriersConnection
} from '@graphql/inbound/types'
import { IWarehouseZoneSuggestion } from '@graphql/warehouse/types'

export interface IGoodsReceipt extends ISearchResult {
  state: number
  stateString: string | null
  deliveredAt: number
  bookedAt: Date | null
  numColli: number
  numPallet: number
  numBlockPallet: number
  numContainer40Ft: number
  numContainer20Ft: number
  numContainer40FtHighCube: number
  numberSkusExpected: number
  numberUniqueSkusExpected: number
  externalId: string
  completionForced: boolean
  waybillNumber: string
  note: string
  createdAt: Date
  updatedAt: Date
  workflow: string
  site: ISite
  goodsReceiptLine: IGoodsReceiptLineConnection
  goodsReceiptLineTodo: IGoodsReceiptLineConnection
  goodsReceiptLineDone: IGoodsReceiptLineConnection
  warehouse: IWarehouse | null
  inboundColli: IInboundColloConnection
  supplier: ICompany
  inboundSortTodoItems: IInboundSortTodoItemsConnection
  inboundSortDoneItems: IInboundSortDoneItemsConnection
  inboundSortLoadCarriers: IInboundSortLoadCarriersConnection
}

export const enum StateEnum {
  CREATED = 0,
  ASN_RECEIVED = 3,
  READY_FOR_PROCESSING = 4,
  PROCESSING = 10,
  READY_FOR_PUTAWAY = 11,
  READY_FOR_BOOKING = 15,
  BOOKED = 20
}

export interface IGoodsReceiptLine extends ISearchResult {
  amount: number
  amountExpected: number
  amountProcessed: number
  cancelled: number
  articleWeight: number
  goodsReceipt: IGoodsReceipt
  goodsReceiptLineLocation: IGoodsReceiptLineLocation[]
  purchaseOrderLine: IPurchaseOrderLine
  cancellationReason: ICancellationReason
  sourceLocation: ILocation | null
  articleBatchRegistrationStrategy: string | null
}

export interface IGoodsReceiptLineLocation extends INode {
  id: ID
  amount: number
  location: ILocation
  goodsReceiptLine: IGoodsReceiptLine
  articleBatch: IArticleBatch
}

export interface ICancellationReason extends INode {
  id: ID
}

export interface IGoodsReceiptQueryContext {
  id: ID
  nextPageCursor?: number
  perPage?: number
  completed: boolean
}

export interface IGoodsEntryQueryContext {
  id: ID
  todoNextPageCursor?: number
  doneNextPageCursor?: number
  perPage?: number
  completed: boolean
}

export interface IGoodsEntryRequest {
  signature: string
  goodsReceiptLineId: ID
  locationId: ID
  originalLocationId?: ID
  quantity: number
  weight?: number
  articleBatchReference?: string
  articleBatchExpireDate?: Date | string | null
}

export interface IDeleteGoodsEntryRequest {
  goodsReceiptLineId: ID
  goodsReceiptLineLocationId: ID
}

export interface IBookReadyRequest {
  id: ID
}

export interface IStartProcessingRequest {
  goodsReceiptId: ID
}

export type IGoodsReceiptLineConnection = IConnection<IGoodsReceiptLine>

export interface IGoodsReceiptResponse extends IGraphQLResponse {
  data: {
    goodsReceiptLine: Partial<IGoodsReceiptLine>
  }
}

export interface IGoodsReceiptLinePrintArticleLabelRequest {
  packStationId: ID
  goodsReceiptLineId: ID
  printAmount: number
  lineAmount: number | null
}

export interface IGoodsReceiptLinePrintArticleLabelResponse extends IGraphQLResponse {
  data: {
    goodsReceiptLinePrintArticleLabel: boolean
  }
}

export interface IGetSortableItemsRequest {
  id: ID
  perPage?: number
  todoNextPageCursor?: string
}

export interface IGetSortableItemsResponse extends IGraphQLResponse {
  data: {
    goodsReceipt: IGoodsReceipt
  }
}

export interface IGetWarehouseZoneSuggestionByGoodsReceiptLineRequest {
  goodsReceiptLineId: ID
}

export interface IGetWarehouseZoneSuggestionByGoodsReceiptLineResponse extends IGraphQLResponse {
  data: {
    getZoneSuggestion: IWarehouseZoneSuggestion | null
  }
}

export interface IInboundSortLocation {
  location: ILocation
  quantity: number
  articleBatch?: IArticleBatch
}

export interface IInboundSortTodoItem extends INode {
  goodsReceiptLineId: ID
  doneQuantity: number
  todoQuantity: number
  article: IArticle
  tags: string[]
}

export class InboundSortTodoItem implements IInboundSortTodoItem {
  public constructor(
    public readonly goodsReceiptLineId: ID,
    public readonly todoQuantity: number,
    public readonly doneQuantity: number,
    public readonly article: IArticle,
    public readonly locations: IInboundSortLocation[]
  ) {}
}

export class IInboundSortLocation implements IInboundSortLocation {
  constructor(
    public readonly location: ILocation,
    private readonly quantity: number,
    private readonly articleBatch?: IArticleBatch
  ) {}
}

export interface IInboundSortDoneItem extends INode {
  goodsReceiptLineId: ID
  doneQuantity: number
  compartmentReference: string
  article: IArticle
  inboundSortLoadCarrier: IInboundSortLoadCarrier
}

export type IInboundSortTodoItemsConnection = IConnection<IInboundSortTodoItem>
export type IInboundSortDoneItemsConnection = IConnection<IInboundSortDoneItem>

export const enum WorkflowEnum {
  DEFAULT = 'default',
  SORT_AND_PUT_AWAY = 'sort_and_put_away'
}

export interface IProcessableReceipt {
  identifier: ID
  totalQuantity: number
  state: string
  supplier: string | null
  purchaseOrderReferences: string[]
  inboundColli: string[]
  creditors: string[]
  tags: string[]
}

export type IProcessableReceiptConnection = IConnection<IProcessableReceipt>

export interface IGetProcessableReceiptsResponse extends IGraphQLResponse {
  data: {
    getProcessableReceipts: IProcessableReceiptConnection
  }
}

export const enum ArticleBatchRegistrationStrategy {
  MANUAL = 'manual'
}
