import { defineStore } from 'pinia'
import outboundLoadCarrierApi from '@graphql/outbound-load-carrier'
import {
  IGetLoadCarrierByIdRequest,
  IOutboundLoadCarrier,
  ICreateLoadCarrierRequest
} from '@graphql/outbound/types'

export const useOutbound = defineStore('outbound', {
  actions: {
    async getLoadCarrierById(request: IGetLoadCarrierByIdRequest) {
      const response = await outboundLoadCarrierApi.getLoadCarrierById(request)

      return response.data.data.getLoadCarrierById
    },
    async getLoadCarrierTypes() {
      const response = await outboundLoadCarrierApi.getLoadCarrierTypes()

      return response.data.data.getLoadCarrierTypes
    },
    async createLoadCarrier(request: ICreateLoadCarrierRequest) {
      const response = await outboundLoadCarrierApi.createLoadCarrier(request)

      return response.data.data.createLoadCarrier
    },
    isLoadCarrierRegistered(loadCarrier: IOutboundLoadCarrier): boolean {
      if (loadCarrier.grossWeight === null) {
        return false
      }

      if (loadCarrier.outerLength === null) {
        return false
      }

      if (loadCarrier.outerWidth === null) {
        return false
      }

      return loadCarrier.outerHeight !== null
    }
  }
})
