import { NonEmptyArray } from '@/typings/types'
import { IArticle, IArticleBatch } from '@graphql/article/types'
import { ILocation } from '@graphql/location/types'
import { IColliConnection, IShipmentConnection } from '@graphql/shipment/types'
import { IConnection, ID, IGraphQLResponse, INode } from '@graphql/types'
import { IUser } from '@store/modules/auth/types'
import { ISearchResult } from '@graphql/search/types'
import { IGoodsReceiptLine } from '@graphql/goodsReceipt/types'
import { IKardexProject } from '@graphql/kardex/types'

export interface IPickBatchLineItem extends INode {
  id: ID
  quantity: number
  quantityPicked: number
  quantityMissed: number
  quantityObsolete: number
  quantityReturned: number
  pickBatchLine: IPickBatchLine
  article: IArticle
  articleBatch: IArticleBatch | null
}

export interface IPickBatchLine extends INode {
  id: ID
  sort_order: number
  location: ILocation
  pickBatchLineItems: IPickBatchLineItem[]
  visited: boolean
  pickBatch: IPickBatch
}

export interface IPickBatchPartition extends INode {
  id: ID
  createdAt: Date
  updatedAt: Date
  pickBatchLines: IPickBatchLine[]
  assignee: IUser
  pickBatch: IPickBatch
  lineCount: number
  processedLines: number
  state: string
  stateForHuman: string
  type: string
  typeForHuman: string
  description: string
  bulkPrintingLabelsAllowed: boolean
}

export interface IPickBatch extends INode {
  id: ID
  createdAt: Date
  state: number | null
  note: string | null
  description: string | null
  humanState: string
  lineCount: number
  processedLines: number
  user: IUser | null
  assignee: IUser | null
  pickBatchLines: IPickBatchLine[]
  searchPreview: string
  pickMethod: string
  packingMethod: string
  strategy: string | null
  shipments: IShipmentConnection
  packedShipments: IShipmentConnection
  unpackedShipments: IShipmentConnection
  colli: IColliConnection
  unpackedColli: IColliConnection
  packedColli: IColliConnection
  pickBatchPartitions: IPickBatchPartition[]
  internalWorkflow: string
}

export interface IStartPickProcessRequest {
  pickBatchPartitionId: ID
}

export interface IGetPickTaskRequest {
  partitionQueue: {
    identifier: string
    type: string
  } | null
}

export interface IGetPickBatchByIdResponse extends IGraphQLResponse {
  data: {
    getPickBatchById: Partial<IPickBatch>
  }
}

export interface ISavePickBatchLineStateMutationResponse extends IGraphQLResponse {
  data: ISavePickBatchStateResponse
}

export interface IGetPickTaskResponse extends IGraphQLResponse {
  data: {
    getPickTask: Partial<IPickBatchPartition>
  }
}

export interface IStartPickProcessResponse extends IGraphQLResponse {
  data: {
    startPickProcess: Partial<IPickBatchPartition>
  }
}

export interface ISavePickBatchStateRequest {
  pickBatchLineId: ID
  pickBatchLineItemsInput: NonEmptyArray<IPickBatchLineItemInput>
}

export interface ISavePickBatchStateResponse {
  savePickBatchLineState: {
    pickBatchMustBeRefreshed: boolean
  }
}

export interface IPickBatchLineItemInput {
  pickBatchLineItemId: ID
  quantityDone: number
  quantityMissed: number
  quantityReturned: number
}

export const enum PackingMethod {
  AUTOPACK = 'autopack',
  MANUAL = 'manual',
  SPECIAL_PACK = 'special_pack'
}

export const enum Strategy {
  SINGLE_ITEM_ORDERS = 'single_item_orders'
}

export interface ILineItem extends INode {
  pickBatchLineItemId: ID
  article: IArticle
  quantityTodo: number
  quantityDone: number
  quantityMissed: number
  scanStrategy: string
  articleBatch: Partial<IArticleBatch>
}

export interface ILine extends INode {
  pickBatchLineId: ID
  location: Partial<ILocation>
  lineItems: ILineItem[]
}

export interface IProgress extends INode {
  total: number
  current: number
  percentage: number
}

export interface IMetaData extends INode {
  key: string
  value: string
}

export interface ILocationToVisitResult extends INode {
  line: ILine | null
  progress: IProgress
  process: string
  loadCarrierLineCount: number
  metaData: IMetaData[]
}

export interface IGetLocationToVisitResponse {
  getLocationToVisit: Partial<ILocationToVisitResult>
}

export interface IGetLocationToVisitQueryResponse extends IGraphQLResponse {
  data: IGetLocationToVisitResponse
}

export const enum Process {
  PICK_ARTICLES = 'pick_articles',
  RETURN_ARTICLES = 'return_articles'
}

export interface IAddLoadCarrierRequest {
  pickBatchLineId: number
  pickBatchLineItemsInput: NonEmptyArray<IPickBatchLineItemInput>
}

export interface IAddLoadCarrierResponse {
  addLoadCarrier: {
    startVerificationProcess: boolean
  }
}

export interface IAddLoadCarrierMutationResponse extends IGraphQLResponse {
  data: IAddLoadCarrierResponse
}

export interface IGetLoadCarrierByIdRequest {
  id: ID
}

export interface IGetLoadCarrierByIdQueryResponse extends IGraphQLResponse {
  data: IGetLoadCarrierByIdResponse
}

export interface IGetLoadCarrierByIdResponse {
  getLoadCarrierById: Partial<ILoadCarrier>
}

export interface ILoadCarrierItem extends INode {
  id: ID
  quantity: number
  quantityProcessed: number | null
  createdAt: Date
  updatedAt: Date
  loadCarrier: ILoadCarrier
  pickBatchLineItem: IPickBatchLineItem
  article: IArticle
  goodsReceiptLine: IGoodsReceiptLine | null
  compartmentReference: string | null
}

export interface ILoadCarrier extends ISearchResult {
  sequence: string | null
  createdAt: Date
  updatedAt: Date
  user: IUser
  startedAt: Date | null
  startedBy: IUser | null
  completedAt: Date | null
  completedBy: IUser | null
  blockedAt: Date | null
  blockedBy: IUser | null
  packedAt: Date | null
  reference: string | null
  pickLoadCarrierItems: LoadCarrierItemConnection
  sortLoadCarrierItems: LoadCarrierItemConnection
  pickBatch: IPickBatch
  pickLoadCarrier: ILoadCarrier
  type: string
  typeForHuman: string
  state: string
  stateForHuman: string
  location?: ILocation | null
}

export type LoadCarrierItemConnection = IConnection<ILoadCarrierItem>

export enum TypeEnum {
  PICK = 'pick',
  OUTBOUND_SORT = 'sort',
  INBOUND_SORT = 'inbound_sort',
  OUTBOUND = 'outbound'
}

export enum StateEnum {
  CREATED = 100,
  STARTED = 300,
  PICKED = 600,
  SORTED = 700,
  PACKING = 750,
  PACKED = 800,
  FINISHED = 1000
}

export enum PartitionState {
  CREATED = 'created',
  STARTED = 'started',
  PICKED = 'picked'
}

export interface IPickBatchPartitionQueue {
  identifier: ID
  name: string
  type: string
}

export enum PartitionTypeEnum {
  DEFAULT = 'default',
  ZONE = 'zone',
  PRIORITY = 'priority'
}

export interface IGetPickBatchPartitionQueuesResponse extends IGraphQLResponse {
  data: {
    getPickBatchPartitionQueues: IPickBatchPartitionQueue[]
  }
}

export interface IStartKardexPickRequest {
  pickBatchPartitionId: ID
}

export interface IStartKardexPickResponse extends IGraphQLResponse {
  data: {
    startKardexPick: IKardexProject
  }
}

export enum InternalWorkflow {
  DEFAULT = 'default',
  SINGLE_SHIPMENT = 'single_shipment'
}
