<template>
  <page-with-search-subscriber v-slot="{ searchSubscriber }" :page-content="false">
    <navigation show-search-icon v-slot="{ searchId }">
      <search-bar :scan-subscriber="searchSubscriber" :search-id="searchId" expandable />
    </navigation>

    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#load-carrier-detail" tab-link-active>Detail</f7-link>
    </f7-toolbar>
    <f7-tabs>
      <f7-tab id="load-carrier-detail" class="page-content" tab-active>
        <load-carrier-detail-tab-content
          v-if="loadCarrier"
          :load-carrier="loadCarrier"
          :is-loading="isLoading"
        />
      </f7-tab>
    </f7-tabs>
    <app-actions v-if="actions.length > 0" :actions="actions" @action-clicked="onActionClick" />

    <register-load-carrier-popup
      v-if="loadCarrier && showRegisterPopup"
      v-model="showRegisterPopup"
      @registered-load-carrier="onLoadCarrierRegistered"
      :selected-load-carrier="{ loadCarrier, registered: true }"
    />
  </page-with-search-subscriber>
</template>
<script lang="ts" setup>
import Navigation from '@components/AppNavigation.vue'
import LoadCarrierDetailTabContent from '@components/pick/LoadCarrierDetailTabContent.vue'
import useLoading from '@composables/useLoading'
import { onMounted, ref } from 'vue'
import { ID } from '@graphql/types'
import AppActions from '@components/AppActions.vue'
import { IAction } from '@components/actions'
import useAction from '@composables/useAction'
import SearchBar from '@components/search/SearchBar.vue'
import PageWithSearchSubscriber from '@pages/PageWithSearchSubscriber.vue'
import { IOutboundLoadCarrier } from '@graphql/outbound/types'
import { useOutbound } from '@store/pinia/outbound/store'
import RegisterLoadCarrierPopup from '@pages/pack/single-shipment/component/RegisterLoadCarrierPopup.vue'
import { IRegisterableLoadCarrier } from '@graphql/pack/types'

const { isLoading, withAsyncLoading } = useLoading()
const { onActionClick } = useAction()
const { getLoadCarrierById, isLoadCarrierRegistered } = useOutbound()

const props = defineProps<{
  loadCarrierId: ID
}>()

const actions = ref<IAction[]>([])
const loadCarrier = ref<IOutboundLoadCarrier | null>(null)
const showRegisterPopup = ref<boolean>(false)

onMounted(async () => {
  await withAsyncLoading(async () => {
    loadCarrier.value = await getLoadCarrierById({ id: props.loadCarrierId })

    actions.value.push({
      name: 'Putaway',
      icon: 'pallet-boxes',
      href: `/load-carrier/${loadCarrier.value!.id}/putaway/`
    })

    if (isLoadCarrierRegistered(loadCarrier.value!)) {
      actions.value.push({
        name: 'Reregister',
        icon: 'weight-scale',
        callback: () => (showRegisterPopup.value = true)
      })
    }
  })
})

const onLoadCarrierRegistered = (loadCarrier: IRegisterableLoadCarrier) => {
  showRegisterPopup.value = false
}
</script>
