import { IConnection, IConnectionRequest, ID, IGraphQLResponse, INode } from '@graphql/types'
import { ILocation } from '@graphql/location/types'
import { IArticle } from '@graphql/article/types'
import { IPickBatch } from '@graphql/pick/types'
import { IUser } from '@store/modules/auth/types'

export interface ILoadCarrierType {
  id: ID
  name: string
}

export interface IPackagingArticle {
  id: ID
  name: string
}

export interface IOutboundTransport extends INode {
  id: ID
  uuid: string
  reference: string
  externalReference: string | null
  courierName: string | null
  createdAt: Date
  updatedAt: Date
  plannedLoadingDate: Date | null
  state: string
  stateForHuman: string
  loadCarriers: [IOutboundLoadCarrier]
}

export interface IOutboundLoadCarrierItem {
  id: ID
  quantity: number
  quantityProcessed: number
  createdAt: Date
  updatedAt: Date
  loadCarrier: IOutboundLoadCarrier
  article: IArticle | null
  childLoadCarrier: IOutboundLoadCarrier | null
  compartmentReference: string | null
}

export type ITodoOutboundTransportConnection = IConnection<IOutboundTransport>
export type IOutboundLoadCarrierItemConnection = IConnection<IOutboundLoadCarrierItem>

export interface IOutboundLoadCarrier {
  id: ID
  sequence: string | null
  createdAt: Date
  updatedAt: Date
  user: IUser
  startedAt: Date | null
  startedBy: IUser | null
  completedAt: Date | null
  completedBy: IUser | null
  blockedAt: Date | null
  blockedBy: IUser | null
  packedAt: Date | null
  reference: string | null
  pickBatch: IPickBatch
  article: IPackagingArticle
  verificationCode: string
  type: string
  typeForHuman: string
  location: ILocation | null
  transport: IOutboundTransport
  loadCarrierItems: IOutboundLoadCarrierItemConnection
  state: string
  stateForHuman: string
  level: number
  outerHeight: number | null
  outerWidth: number | null
  outerLength: number | null
  grossWeight: number | null
}

export interface IGetTodoTransportQueryRequest extends IConnectionRequest {}

export interface IGetTodoTransportsQueryResponse extends IGraphQLResponse {
  data: {
    getTodoTransports: ITodoOutboundTransportConnection
  }
}

export interface IGetTransportByIdQueryRequest {
  transportId: ID
}

export interface IGetTransportByIdQueryResponse extends IGraphQLResponse {
  data: {
    getTransportById: IOutboundTransport
  }
}

export interface IPickableLoadCarrier {
  id: ID
  reference: string
  locationName: string
  picked: boolean
}

export interface IGetPickableLoadCarriersQueryRequest {
  transportId: ID
}

export interface IGetPickableLoadCarriersQueryResponse extends IGraphQLResponse {
  data: {
    getPickableLoadCarriers: IPickableLoadCarrier[]
  }
}

export interface IMarkLoadCarrierPickedMutationRequest {
  loadCarrierId: ID
}

export interface IMarkLoadCarrierPickedMutationResponse extends IGraphQLResponse {
  data: {
    markLoadCarrierPicked: boolean
  }
}

export interface IFinalizePickOfTransportMutationRequest {
  transportId: ID
}

export interface IFinalizePickOfTransportMutationResponse extends IGraphQLResponse {
  data: {
    finalizePick: boolean
  }
}

export interface IStartProcessingOfTransportMutationRequest {
  transportId: ID
}

export interface IStartProcessingOfTransportMutationResponse extends IGraphQLResponse {
  data: {
    startProcessingOfTransport: boolean
  }
}

export const enum OutboundTransportState {
  NEW = 'new',
  PROCESSING = 'processing',
  COMPLETED = 'completed'
}

export const enum OutboundLoadCarrierState {
  TODO = 'todo',
  PICKED = 'picked',
  SHIPPED = 'shipped'
}

export interface IGetLoadCarrierTypesResponse extends IGraphQLResponse {
  data: {
    getLoadCarrierTypes: ILoadCarrierType[]
  }
}

export interface ICreateLoadCarrierRequest {
  articleId: ID
  packProjectId: ID
}

export interface ICreateLoadCarrierResponse extends IGraphQLResponse {
  data: {
    createLoadCarrier: IOutboundLoadCarrier
  }
}

export interface IGetLoadCarrierByIdRequest {
  id: ID
}

export interface IGetLoadCarrierByIdResponse extends IGraphQLResponse {
  data: {
    getLoadCarrierById: IOutboundLoadCarrier
  }
}
