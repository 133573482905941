<template>
  <f7-block-header>Collo Type</f7-block-header>
  <f7-card>
    <f7-card-content :padding="false">
      <f7-list>
        <f7-list-item v-if="isEdit">
          {{ getColloTypeForHuman(form.type.value) }}
        </f7-list-item>
        <f7-list-item
          v-else
          :readonly="isEdit"
          radio
          radio-icon="start"
          v-for="colloTypeOption in colloTypeOptions"
          :key="colloTypeOption.key"
          name="collo-type"
          :title="colloTypeOption.value"
          @change="form.type.value = colloTypeOption.key"
          :checked="form.type.value === colloTypeOption.key"
        />
        <f7-list-item text-color="red" v-if="form.type.errors?.length > 0 ?? false">
          {{ form.type.errors[0] ?? '' }}
        </f7-list-item>
      </f7-list>
    </f7-card-content>
  </f7-card>
  <f7-block-header>Quantity</f7-block-header>
  <f7-card>
    <f7-card-content>
      <f7-list form id="collo-form">
        <f7-list-input
          type="hidden"
          :error-message-force="form.quantity.errors?.length > 0 ?? false"
        >
          <template #input>
            <input-number
              :value="form.quantity.value"
              name="quantity"
              :max-value="99"
              required
              :should-confirm="false"
              @change="(value) => (form.quantity.value = value)"
            />
          </template>
          <template #error-message>
            {{ form.quantity.errors[0] ?? '' }}
          </template>
        </f7-list-input>
      </f7-list>
    </f7-card-content>
  </f7-card>
  <f7-card>
    <f7-button large color="dimass" text-color="white" @click="onSubmit">Save</f7-button>
  </f7-card>
</template>
<script setup lang="ts">
import { colloTypeOptions } from '@graphql/inbound/types'
import InputNumber from '@components/form/InputNumber.vue'
import { IQuantityInboundColloTypeFormInput } from '@store/pinia/inbound/types'
import { IPackableItem } from '@graphql/pack/types'
import { ref, watch } from 'vue'
import useInbound from '@composables/useInbound'

const props = defineProps<{
  value: IQuantityInboundColloTypeFormInput
  isEdit: boolean
}>()

const emits = defineEmits<{
  (e: 'submitted', packableItem: IPackableItem)
}>()

const form = ref<IFormInterface>(props.value)

const { findIndexOfExistingQuantityPerColloItem, getColloTypeForHuman } = useInbound()
const isValidateForm = () => {
  form.value.type.errors = []
  form.value.quantity.errors = []

  let isValid = true

  const exitingType = findIndexOfExistingQuantityPerColloItem(form.value)
  if (exitingType !== -1 && !props.isEdit) {
    form.value.type.errors.push(
      `Collo of type ${getColloTypeForHuman(form.value.type.value)} already exists!`
    )
    isValid = false
  }

  if (form.value.type.value === '') {
    form.value.type.errors.push(`Collo type is required!`)
    isValid = false
  }

  if (form.value.quantity.value <= 0) {
    form.value.quantity.errors.push(`Quantity must be a positive number!`)
    isValid = false
  }

  return isValid
}

const onSubmit = () => {
  if (!isValidateForm()) {
    return
  }

  emits('submitted', form.value)
}

watch(
  () => props.value,
  (value) => {
    form.value = value
  }
)
</script>
